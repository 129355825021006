import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import lotDetailService from "../js/receiving-service";
import html2pdf from "html2pdf.js";
import commonService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchText: "",
      selectedProject: "",
      projectList: [],
      selectedCarrier: "",
      carrierList: [],
      totalRecords: 0,
      unresolvedTrackingList: [],
      exportDisable: true,
      carrierDisabled: true,
      searchButton: true,
      excelName: "",
      trackingData: [],
      primaryName: "",
      printDisabled: true,
      pdfName: "",
      json_fields: {
        Project: "project",
        Carrier: "carrier",
        "Tracking Number": "trackingnumber",
        "Staging Location": "stagingLocation",
        "Log Date/Time": "logged_date",
        "Logged By": "logged_by",
      },
      unresolvedTrackingHeaders: [
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Carrier", value: "carrier", class: "primary customwhite--text" },
        { text: "Tracking Number", value: "trackingnumber", class: "primary customwhite--text" },
        { text: "Staging Location", value: "stagingLocation", class: "primary customwhite--text" },
        { text: "Log Date/Time", value: "logged_date", class: "primary customwhite--text" },
        { text: "Logged By", value: "logged_by", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectDetails();
  },
  mounted() {},
  methods: {
    //Export pdf
    printDetails() {
      this.pdfName =
        "Unresolved_Tracking_Number_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(document.getElementById("trackingPDF"), {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
    },
    // File name for Downloading excel
    fileName() {
      this.excelName =
        "Unresolved_Tracking_Number_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Reset Function
    resetFunction() {
      this.searchText = "";
      this.selectedProject = "";
      this.projectList = [];
      this.selectedCarrier = "";
      this.carrierList = [];
      this.totalRecords = 0;
      this.unresolvedTrackingList = [];
      this.exportDisable = true;
      this.carrierDisabled = true;
      this.searchButton = true;
      this.excelName = "";
      this.trackingData = [];
      this.printDisabled = true;
      this.getProjectDetails();
    },
    //Getting project Details
    async getProjectDetails() {
      let projData = await commonService.getProjectAll("get", this.userId);
      if (projData !== null && projData !== undefined) {
        this.projectList = projData.ProjectInfo;
      }
    },
    //Project Change
    async projectChange() {
      let carrierData = await commonService.getCarrierList("get", this.userId);
      if (carrierData !== null && carrierData !== undefined) {
        this.carrierList = carrierData;
        this.carrierDisabled = false;
      }
    },
    //Carrier Change
    carrierChange() {
      if (this.selectedProject !== "" && this.selectedCarrier.length !== 0) {
        this.searchButton = false;
      } else {
        this.searchButton = true;
      }
    },
    //Lot Search button click event
    async onSearchClick() {
      this.unresolvedTrackingList = [];
      let searchObj = {
        UserId: parseInt(this.userId),
        PageNumber: 1,
        SearchType: 1,
        AdvJson: JSON.stringify({
          Proj_Key: parseInt(this.selectedProject),
          Carrier: this.selectedCarrier,
        }),
      };
      this.trackingData = await lotDetailService.searchUnresolvedTrackingNum("post", searchObj);
      if (this.trackingData !== null && this.trackingData !== undefined) {
        let data = this.trackingData.Resultset;
        if (data !== "") {
          this.unresolvedTrackingList = data.map((obj) => {
            return {
              ...obj,
              logged_date: Utility.convertESTToLocal(obj.logged_date),
            };
          });
          if (this.trackingData.TotalRecords > 0) {
            this.exportDisable = false;
            this.totalRecords = this.trackingData.TotalRecords;
            this.printDisabled = false;
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No data available.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.exportDisable = true;
          this.totalRecords = 0;
          this.printDisabled = true;
        }
      }
    },
    //On Search Cancel button click event
    onSearchCancel() {
      this.resetFunction();
    },
  },
  components: {
    breadcrumbComp,
    html2pdf,
  },
};
